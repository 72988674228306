:global(#app) {
  .author {
    color: #fff;
    display: inline-block;
    font-weight: bold;
    line-height: 20px;
    margin-right: 8px;
  }

  .content {
    border-bottom: 1px solid #092d4221;
    display: inline-block;
    padding-bottom: 14px;
    vertical-align: top;
    width: calc(100% - 40px);
   
  }

  .date {
    color: #6b808c;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
  }

  .text {
    background: #1f2327;
    border-radius: 0px 8px 8px;
    box-shadow:
      0 1px 2px -1px rgba(9, 30, 66, 0.25),
      0 0 0 1px rgba(9, 30, 66, 0.08);
    box-sizing: border-box;
    color: #fff;
    display: inline-block;
    margin: 1px 2px 4px 1px;
    max-width: 100%;
    overflow: hidden;
    padding: 8px 12px;
    transition: box-shadow 0.3s ease-in-out;
    
    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .title {
    padding-bottom: 4px;
  }

  .user {
    display: inline-block;
    padding: 4px 8px 0 0;
    vertical-align: top;
  }
  .reply {
    margin-left: 20px;
    padding-left: 10px;
    margin-top: 5px;
    
    color: white;
  }
  .replyBox {
    margin-left: 20px;
    padding-left: 10px;
    margin-top: 5px;
  }
  .replyButton {
    margin-top: 5px;
  }
  .avatarImage {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
  /* ItemComment.module.scss */

  .commentContainer {
    display: flex;
    align-items: flex-start;
  }

  .avatar {
    margin-right: 10px;
  }

  .avatarImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .content {
    flex: 1;
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .author {
    font-weight: bold;
    margin-right: 10px;
  }

  .date {
    font-size: 0.9em;
    color: grey;
  }

  

  .reply {
    margin-top: 10px;
    margin-left: 20px;
  }

  .replyContainer {
    display: flex;
    align-items: flex-start;
  }

  .replyAvatar {
    margin-right: 10px;
  }

  .replyContent {
    flex: 1;
  }

  .replyTitle {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .replyAuthor {
    font-weight: bold;
    margin-right: 10px;
  }

  .replyDate {
    font-size: 0.9em;
    color: grey;
  }

  .replyBox {
    margin-top: 10px;
  }

  .nestedReply {
    margin-top: 10px;
    margin-left: 20px;
  }
}
