:global(#app) {
  .actionsButton {
    background: none;
    box-shadow: none;
    border-radius: 3px;
    box-sizing: content-box;
    color: #798d99;
    display: inline-block;
    margin: 0;
    min-height: auto;
    opacity: 0;
    outline: none;
    padding: 4px;
    position: absolute;
    right: 2px;
    top: 2px;
    transition: background 85ms ease;
    width: 20px;

    &:hover {
      background: #101204; /* Cambiado a un tono oscuro */
      color: #cccccc; /* Cambiado a un color más claro para destacar en el oscuro */
    }
  }

  .attachment {
    display: inline-block;
    line-height: 0;
    margin: 0 0 6px 0;
    max-width: 100%;
    vertical-align: top;
  }

  .attachmentLeft {
    margin-right: 4px;
  }

  .attachmentRight {
    margin-left: 2px;
  }

  .attachments {
    display: inline-block;
    padding-bottom: 2px;
  }

  .attachmentsRight {
    float: right;
    line-height: 0;
  }

  .card {
    background: #22272b; /* Cambiado a un tono oscuro */
    border-radius: 6px;
    box-shadow: 0 1px 0 #101204; /* Cambiado a un tono más oscuro para destacar */
    position: relative;
  
    &:hover {
      background: #3a3a3a; /* Cambiado a un tono oscuro */
      border-bottom-color: rgba(
        255,
        255,
        255,
        0.1
      ); /* Cambiado para mantener el contraste en el modo oscuro */

      .target {
        opacity: 1;
      }
    }
  }

  .content {
    
    padding-top: 10px;
    display: block;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .cover {
    border-radius: 3px 3px 0 0;
    margin-bottom: 2px;
    padding: 2px 2px 0;
    vertical-align: middle;
    width: 100%;
  }

  .details {
    padding: 6px 8px 0;
  }

  .labels {
    display: block;
    max-width: 100%;
    overflow: hidden;
  }

  .name {
    color: #cccccc; /* Cambiado a un color más claro para destacar en el oscuro */
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 6px;
    word-wrap: break-word;
  }

  .notification {
    background: #ff3c3c; /* Cambiado a un color rojo oscuro para resaltar */
    color: #ffffff;
    font-size: 12px;
    line-height: 20px;
    padding: 0px 6px;
    border: none;
    border-radius: 3px;
    display: inline-block;
    outline: none;
    text-align: left;
    transition: background 0.3s ease;
    vertical-align: top;
  }

  .wrapper {
    cursor: auto;
    display: block;
    margin-bottom: 8px;
  }
}
