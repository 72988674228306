:global(#app) {
  .addButton {
    background: transparent;
    color: #fff;
    margin-right: 0;
    vertical-align: top;

    &:hover {
      background: rgba(34, 36, 38, 0.3);
    }
  }.hidden-list {
    max-height: 100px; /* Altura máxima de la lista comprimida */
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .hidden-list.expanded {
    max-height: 1000px; /* Altura máxima de la lista expandida, ajusta según sea necesario */
  }
  

  .editButton {
    background: transparent;
    box-shadow: none;
    color: #fff;
    line-height: 32px;
    margin-right: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 2px;
    top: 2px;
    width: 32px;

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }
  }

  .link {
    color: rgba(255, 255, 255, 0.72);
    cursor: pointer;
    display: block;
    line-height: 20px;
    padding: 10px 34px 6px 14px;
    text-overflow: ellipsis;
    max-width: 400px;
    overflow: hidden;
  }

  .tab {
    border-radius: 3px 3px 0 0;
    min-width: 100px;
    position: relative;
    transition: all 0.1s ease;
    border-radius: 5px;

    &:hover {
      background: rgba(0, 0, 0, 0.24);

      .target {
        opacity: 1;
      }
    }
  }

  .tabActive {
    background: rgba(0, 0, 0, 0.24);

    &:hover {
      background: rgba(0, 0, 0, 0.32);
    }

    .link {
      color: #fff;
      font-weight: bold;
    }
  }

  .tabWrapper {
    display: flex;
    flex: 0 0 auto;
  }

  .tabs {
    display: flex;
    height: 38px;
    flex: 0 0 auto;
    white-space: nowrap;
  }

  .tabsWrapper {
    display: flex;
    flex: 0 0 auto;
    height: 56px;
    overflow-x: auto;
    overflow-y: hidden;

    &:hover {
      height: 38px;
    }

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
    }
  }

  .wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 38px;
    overflow: hidden;
  }
  .hiddenList {
    max-height: 100px; /* Altura máxima de la lista comprimida */
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .hiddenList.expanded {
    max-height: 1000px; /* Altura máxima de la lista expandida, ajusta según sea necesario */
  }
  
}
