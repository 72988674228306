@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,700");

.header {
  color: #202125;
  margin-bottom: 40px;

}
.header h2 {
  font-weight: 600;
}
.header p {
  font-weight: 300;
}

.wrapper {
  max-width: 100%;
  min-width: 100%;
  margin: 0 0;
  padding: 0;
  background-color: #202125;
  border-radius: 12px;
  overflow-y: auto;
}

.gantt {
  display: grid;
  border: 0;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  height: 80vh !important;
}
.gantt__row {
  display: grid;
  grid-template-columns: 150px 1fr;
  background-color: #202125;
}
.gantt__row:nth-child(odd) {
  background-color: #202125;
}
.gantt__row:nth-child(odd) .gantt__row-first {
  background-color: hsl(228, 7%, 14%);
  color: #fff;
}
.gantt__row--empty {
  background-color: #202125 !important;
  z-index: 1;
}
.gantt__row--empty .gantt__row-first {
  border-width: 1px 1px 0 0;
}
.gantt__row--lines {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  grid-template-columns: 150px repeat(12, 1fr);
}

.porcent{
  width: 100%;
}

.gantt__row--lines span {
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.144);
  
}
.gantt__row--lines .marker {
  background-color: rgba(8, 218, 96, 0.089);
}



.gantt__row--months {
  color: #fff;
  background-color: transparent !important;
  border-bottom: 1px solid rgba(223, 4, 4, 0.1);
  grid-template-columns: 150px repeat(12, 1fr);
}
.gantt__row--months .gantt__row-first {
  border-top: 0 !important;
  background-color: #202125 !important;
}
.gantt__row--months span {
  text-align: center;
  font-size: 13px;
  align-self: center;
  font-weight: bold;
  padding: 20px 0;
}
.gantt__row-first {
  background-color: #202125;
  border-width: 1px 0 0 0;
  text-align: center;
  font-size: 13px;
  justify-content: center;
  color: #fff;
  border-style: solid;
  padding: 15px 0;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.gantt__row-bars {
  list-style: none;
  display: grid;
  padding: 9px 0;
  margin: 0;
  grid-template-columns: repeat(365, 1fr);
  grid-gap: 8px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.712);
}


.gantt__row-bars li.stripes {
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, 0.1) 5px, rgba(255, 255, 255, 0.1) 12px);
}



.gantt__row-bars li:before,
.gantt__row-bars li:after {
  content: "";
  height: 100%;
  top: 0;
  z-index: 4;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}

.gantt__row-bars li:before {
  left: 0;
}

.gantt__row-bars li:after {
  right: 0;
}
.gantt__row-bars li {
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  min-height: 15px;
  padding: 5px 12px;
  color: white;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
}
.gantt__row-bars li.stripes {
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(248, 0, 0, 0.1) 5px, rgba(255, 255, 255, 0.1) 12px);
}
.gantt__row-bars li:before, .gantt__row-bars li:after {
  content: "";
  height: 100%;
  top: 0;
  z-index: 4;
  position: absolute;
}
.gantt__row-bars li:before {
  left: 0;
}
.gantt__row-bars li:after {
  right: 0;
}


