:global(#app) {
  .progress {
    margin: 0 0 16px;
  }

  .progressWrapper {
    display: inline-block;
    padding: 3px 0;
    vertical-align: top;
    width: calc(100% - 50px);
  }

  .count {
    color: #8c8c8c;
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    vertical-align: top;
    width: 50px;
  }

  .taskButton {
    background: transparent;
    border: none;
    border-radius: 3px;
    color: #a0a0a0;
    cursor: pointer;
    display: block;
    margin-top: 6px;
    min-height: 30px; /* Adjust the height as desired */
    outline: none;
    padding: 4px 6px; /* Adjust the padding as desired */
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;

    //Efecto de elevación
    box-shadow: none; /* Remove the box shadow */

    &:hover {
      background: transparent;
      color: #ffffff;
    }
  }

  .taskButtonText {
    position: absolute;
    top: 12px;
  }
}
