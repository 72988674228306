:global(#app) {
  .button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    outline: none;
    padding: 0;
  }

  .wrapper {
    background: #202020;
    border: none;
    border-radius: 3px;
    color: #c0c0c0;
    display: inline-block;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.404);
    text-align: left;
    transition: background 0.3s ease;
    vertical-align: top;
  }

  .wrapperHoverable:hover {
    background: #000000;
    color: #fff;
  }

  /* Sizes */

  .wrapperTiny {
    font-size: 12px;
    line-height: 20px;
    padding: 0px 6px;
  }

  .wrapperSmall {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 6px;
  }

  .wrapperMedium {
    line-height: 20px;
    padding: 6px 12px;
  }

  .div{
    background-color: red;
  }
}
