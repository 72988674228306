:global(#app) {
  .controls {
    clear: both;
    margin-top: 6px;
  }

  .field {
    background: #1f2327;
    border: 0;
    box-sizing: border-box;
    color: #fff;
    display: block;
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 6px;
    overflow: hidden;
    padding: 8px 12px;
    resize: none;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #6b6b6b;
    }
  }

  .selectFloat {
    color: #fff;
    background-color: #1f2327;
    position: absolute;
    z-index: 1000;
    top: -100px;
    border-radius: 5px;
    padding: 5px;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
  }

  .selectFloat li {
    padding: 5px;
    cursor: pointer;
  }

  .selectedOption {
    background-color: #656565;
  }
}
