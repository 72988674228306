:global(#app) {
  .wrapper {
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: -1;
    //Blur
    filter: blur(5px);
  }

}
