:global(#app) {
  .controls {
    clear: both;
    margin-top: 6px;
  }

  .field {
    background: #fff;
    color: red;
    display: block;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 4px;
    overflow: hidden;
    resize: none;

    &:focus {
      outline: none;
    }
  }

  //Textarea dark mode
  .field{
    background: #1f2327;
    border-radius: 8px;
    box-shadow:
      0 1px 2px -1px rgba(9, 30, 66, 0.25),
      0 0 0 1px rgba(9, 30, 66, 0.08);
    box-sizing: border-box;
    color: #fff;
    display: block;
    margin: 1px 2px 4px 1px;
    max-width: 100%;
    overflow: hidden;
    padding: 8px 12px;
    transition: box-shadow 0.3s ease-in-out;
    
    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

}
