:global(#app) {
  .field {
    border: none;
    border-color: #bbb;
    border-radius: 3px;
    color: #fff;
    display: block;
    font-weight: bold;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: 4px 8px;
    resize: none;
    width: 100%;

    &:focus {
      background: #070707;
      border-color: #5ba4cf;
      box-shadow: 0 0 0 1px #5ba4cf;
    }
    p {
      color: #fff;
    }
  }
}
