:global(#app) {
  .button {
    min-height: 30px;
    vertical-align: top;
  }

  .controls {
    margin-top: 4px;
  }

  .field {
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 0 #ccc;
    color: #333;
    outline: none;
    overflow: hidden;
    width: 100%;

    &:focus {
      border-color: #298fca;
      box-shadow: 0 0 2px #298fca;
    }
  }
  .wrapper {
    background: #333;
    border-radius: 3px;
    padding: 4px;
    transition: opacity 40ms ease-in;
    width: 272px;
  }
}
