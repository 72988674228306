:global(#app) {
  .actionButton {
    background: rgba(43, 43, 43, 0.363); 

    backdrop-filter: blur(20px);
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.232);
    color: #f8f8f8;
    margin-top: 12px;
    padding: 12px 20px;
    text-align: left;
    text-overflow: ellipsis;
    transition: all 0.3s ease;
    white-space: nowrap;
    

    transition: background 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      color: #ffffff;
      
    }
  }

  .actionIcon {
    color: #ffffff;
    display: inline;
    margin-right: 8px;
  }

  .actions {
    
    margin-bottom: 24px;
  }

  .actionsTitle {
    color: #a0a0a0;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.05em;
    margin-top: 16px;
    text-transform: uppercase;
    line-height: 22px;
    margin-bottom: -4px;
    cursor: pointer;
  }

  .addAttachment {
    margin: 0 -4.3px;
    text-decoration: none;
  }

  .attachment {
    display: inline-block;
    margin: 0 4px 8px 0;
    max-width: 100%;
  }

  .attachments {
    display: inline-block;
    margin: 0 8px 8px 0;
    max-width: 100%;
    vertical-align: top;
    
  }

  .contentModule {
    margin-bottom: 24px;
    //Efecto de elevación
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.178);
    backdrop-filter: blur(12px);
    border-radius: 10px;
    background-color: #2c2e30 !important;
    padding: 24px;
    
    
  }

  .contentPadding {
    padding: 12px 16px;
    
  }

  .cursorPointer {
    cursor: pointer;
  }

  .dueDate {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 4px;
    color: #e0e0e0;
    cursor: pointer;
    line-height: 20px;
    outline: none;
    padding: 8px 16px;
    text-align: left;
    text-decoration: underline;
    transition: background 0.3s ease;
    vertical-align: top;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      color: #ffffff;
    }
  }

  .descriptionButton {
    background: rgba(50, 50, 50, 0.1); 
    border: none;
    border-radius: 4px;
    display: block;
    color: #e0e0e0;
    cursor: pointer;
    min-height: 54px;
    outline: none;
    padding: 12px 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;

    &:hover {
      background: rgba(50, 50, 50, 0.5);

      color: #ffffff;
    }
  }

  .descriptionButtonText {
    position: absolute;
    top: 12px;
  }

  .descriptionText {
    background: transparent;
    border: none;
    color: #e0e0e0;
    margin-bottom: 12px;
    outline: none;
    overflow: hidden;
    padding: 0;
    text-align: left;
    width: 100%;
    
  }

  .grid {
    background: #2c2f33;
    margin: 0;
    
  }

  .headerPadding {
    padding: 0;
  }

  .headerTitle {
    color: #f0f0f0;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
  }

  .headerTitleWrapper {
    margin: 4px 0;
    padding: 6px 0 0;
  }

  .headerWrapper {
    margin: 12px 48px 12px 56px;
    position: relative;
    
  }

  .modalPadding {
    padding: 0px;
  }

  .moduleHeader {
    color: #f0f0f0;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin: 0 0 8px;
    padding: 8px 0;
  }

  .moduleIcon {
    color: #f0f0f0;
    font-size: 18px;
    height: 32px;
    left: -40px;
    line-height: 32px;
    margin-right: 0;
    position: absolute;
    top: 2px;
    width: 32px;
  }

  .moduleWrapper {
    margin: 0 0 0 40px;
    position: relative;
    
  }

  .sidebarPadding {
    padding: 12px 16px;
    
  }

  .text {
    color: #f0f0f0;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 22px;
    margin: 0 8px 4px 0;
    text-transform: uppercase;
    
  }

  .wrapper {
    width: 900px;
    border-radius: 15px;
    background: #2c2f33;
    margin: 0 auto;
    padding: 24px;
    position: relative;
    z-index: 1;

  }

  @media (max-width: 926px) {
    .wrapper {
      width: 95%;
    }
  }
}
